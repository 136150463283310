<template>
  <main>
    <!-- application form account-info change -->
    <section id="register_points_search_payment">
      <div class="w1300 layout_container">

        <!-- 表單主標題 -->
        <div class="main_title_box">
          <h3 class="main_title txt-bold">積分申請繳費</h3>
          <div class="img_box">
            <img src="@/statics/img/index/icon_title2.svg" alt="">
          </div>
        </div>

        <div class="main_description_box mg-b-50">
          <h4 class="main_description_title txt-light_green txt-bold">繳費說明</h4>
          <ul class="number_list mg-b-10">
            <li><p>行政處理費用計費方式：1-8小時活動1000元，9-16小時活動2000元，以此類推。</p></li>
            <li><p>如有其他問題，請聯絡台灣居家醫療醫學會秘書處。</p></li>
          </ul>
          <p>聯絡電話：{{ contact_info.tel }}</p>
          <p>電子郵件：{{ contact_info.email }}</p>
        </div>

        <div class="main_description_box mg-b-50">
          <p>申請單位：{{ applyHost }}</p>
          <p>活動主題：{{ applyTopic }}</p>
          <p>活動時間：{{ applyStart }} ~ {{ applyEnd }}</p>
          <p>時數：{{ applyHour }}</p>
        </div>

        <!-- table area -->
        <div class="table_box">
          <table class="txt-center">
            <thead>
              <tr>
                <th class="td-main txt-left"><p>{{ th1 }}</p></th>
                <th class="min180"><p>{{ th2 }}</p></th>
                <th class="min100"><p>{{ th3 }}</p></th>
              </tr>
            </thead>
            <tbody id="table_data">

              <tr v-for="item in applyOrders" :key="item.uuid">
                <td :data-title="th1" class="td-main txt-left"><p>{{ item.content }}</p></td>
                <td :data-title="th2" class="td-input">
                  <input
                    type="text"
                    placeholder="請輸入收據抬頭"
                    v-model="item.title"
                  >
                </td>
                <td :data-title="th3"><p>{{ item.total }}</p></td>
              </tr>

            </tbody>
          </table>
        </div>

        <div class="payment_check txt-right cf active">
          <p class="payment_check_total">
            總金額 <span class="txt-light_green">{{ applyAmount }}</span> 元
          </p>
          <div class="filter_box payment_check_container fright">
            <SelectPayment
              id="pay_type"
              v-model="formData.pay_id"
              :item="inputFormat.paymentSelect"
              :rules="[
                val => !!val || '必選欄位！',
              ]"
            />

            <!-- 信用卡繳費：綠界金流 -->
            <button
              type="button"
              class="btn p"
              @click="onSubmit"
            >前往繳費</button>
          </div>
        </div>

      </div>
    </section>
  </main>
</template>
<script>
import SelectPayment from '@/components/form/SelectPayment.vue';
import { mapActions } from 'vuex';
import { eventsApplyView } from '@/lib/http';
import _ from 'lodash';

export default {
  name: 'RegisterPointSearchPayment',
  data() {
    return {
      th1: '費用名稱',
      th2: '收據抬頭',
      th3: '金額',
      applyID: '',
      applyInfo: {},
      contact_info: {
        tel: '0988-206590',
        email: 'hmca.tw@gmail.com',
      },
      inputFormat: {
        paymentSelect: {
          placeHolder: '請選擇繳費方式',
          options: [
            {
              name: '信用卡',
              value: 1,
            },
            // {
            //   name: 'ATM轉帳',
            //   value: 2,
            // },
            // {
            //   name: '超商代碼繳費',
            //   value: 3,
            // },
          ],
        },
      },
      formData: {
        order_uuid: '',
        pay_id: 1,
        detail: [],
        return_url: '',
      },
    };
  },
  created() {
    if (this.$route.query && this.$route.query.uuid) {
      this.applyID = this.$route.query.uuid;
      eventsApplyView({ uuid: this.applyID }).then((result) => {
        this.applyInfo = _.get(result, 'data.result.data', {});
        this.formData.order_uuid = _.get(result, 'data.result.data.order.uuid', '');
        this.formData.return_url = '/register/point/search/success';
      });
    } else {
      this.openModal({
        url: '/register/point/search',
        message: '請返回積分申請查詢頁選擇欲繳費項目',
      });
    }
  },
  computed: {
    applyHost() {
      return _.get(this.applyInfo, 'apply_host', '');
    },
    applyTopic() {
      return _.get(this.applyInfo, 'name', '');
    },
    applyStart() {
      return _.get(this.applyInfo, 'start_date', '');
    },
    applyEnd() {
      return _.get(this.applyInfo, 'end_date', '');
    },
    applyHour() {
      return _.get(this.applyInfo, 'hours');
    },
    applyOrders() {
      return _.get(this.applyInfo, 'order.order_details', []);
    },
    applyAmount() {
      return _.get(this.applyInfo, 'order.total', '');
    },
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        this.applyOrders.forEach((item) => {
          this.formData.detail.push({
            uuid: item.uuid,
            title: item.title,
          });
        });
        this.$cookies.set('applyPointInfo', JSON.stringify(this.formData));
        this.$cookies.set('applyPointHost', this.applyHost);
        this.$router.push({ path: `/register/point/search/confirm?uuid=${this.applyID}` });
      } else {
        this.openModal({
          message: '必填欄位未完成',
        });
      }
    },
  },
  components: {
    SelectPayment,
  },
};
</script>
